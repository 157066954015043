import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';
import Glide from '@glidejs/glide';
import { DisposalSet, checkSelector, onReady } from './utils';
const disposalSet = new DisposalSet();
onReady(() => {
  const sliderBlocks = document.querySelectorAll('.afc-slider');
  for (const sliderBlock of sliderBlocks) {
    const sliderEl = sliderBlock.querySelector('.glide');
    checkSelector(sliderEl, 'slider');
    const container = sliderEl.querySelector('.glide__slides');
    checkSelector(container, 'slides container');
    const slider = new Glide(sliderEl, {
      type: 'slider',
      perView: 3,
      gap: 30,
      bound: true,
      rewind: false,
      breakpoints: {
        320: {
          perView: 1,
          gap: 20,
          peek: {
            before: 0,
            after: 64
          }
        },
        360: {
          perView: 1,
          gap: 20,
          peek: {
            before: 0,
            after: 96
          }
        },
        480: {
          perView: 1,
          gap: 20,
          peek: {
            before: 0,
            after: 128
          }
        },
        768: {
          perView: 2,
          gap: 30
        },
        1024: {
          perView: 3,
          gap: 40
        }
      }
    });
    slider.mount();
    disposalSet.register(() => slider.destroy());
  }
});
if (import.meta.webpackHot) {
  import.meta.webpackHot.dispose(data => {
    disposalSet.disposeAll();
  });
  import.meta.webpackHot.accept();
}