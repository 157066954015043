import { onReady } from './utils';
onReady(() => {
  let pageBody = document.getElementsByTagName('body')[0];
  // if (pageBody.classList.contains('logged-in')) {
  let feedbackForm = document.querySelector('.feedback-form');
  let openBtn = document.querySelector('.feedback-form-button');
  let closeBtn = document.querySelector('.feedback-close-button');
  let formClasses;
  if (feedbackForm !== null) {
    formClasses = Array.from(feedbackForm.classList);
    let formIdIndex = formClasses.findIndex(element => element.includes("gf-form-"));
    let formIdArray = formClasses[formIdIndex].split('-');
    let formId = formIdArray[formIdArray.length - 1];
    if (!document.querySelector(`#gform_wrapper_${formId}`)) {
      openBtn.parentElement.classList.add('hide');
    }
    if (openBtn || closeBtn) {
      openBtn.addEventListener('click', function () {
        feedbackForm.classList.add('active');
        pageBody.classList.add('feedback-form-open');
        openBtn.parentElement.classList.add('hide');
        trapFocus(feedbackForm);
      });
      function closeFeedback() {
        feedbackForm.classList.remove('active');
        pageBody.classList.remove('feedback-form-open');
        openBtn.parentElement.classList.remove('hide');
      }
      closeBtn.addEventListener('click', closeFeedback);
      window.onclick = function (event) {
        if (event.target == feedbackForm) {
          closeFeedback();
        }
      };
    }
  }
  // }
});

// https://hidde.blog/using-javascript-to-trap-focus-in-an-element/
let trapFocus = function (element) {
  var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), input[type="email"]:not([disabled]),  input[type="submit"]:not([disabled]), input[type="file"]:not([disabled]), select:not([disabled])');
  var firstFocusableEl = focusableEls[0];
  var lastFocusableEl = focusableEls[focusableEls.length - 1];
  var KEYCODE_TAB = 9;
  focusableEls[1].focus();
  element.addEventListener('keydown', function (e) {
    var isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;
    if (!isTabPressed) {
      return;
    }
    if (e.shiftKey) /* shift + tab */{
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else /* tab */{
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
  });
};