import { onReady } from './utils';
onReady(() => {
  // Source: https://www.w3.org/WAI/tutorials/menus/flyout/
  var menuItems = document.querySelectorAll('.afc-header li.menu-item-has-children');

  // Remove level 2 sub-menu from functionality
  let menuSubItems = document.querySelectorAll('.afc-header li.menu-item-has-children .menu-item-has-children');
  let topMenuItems = [];

  // Add class to hero to stop wavy animation
  let wavyHero = document.querySelector('.afc-hero--wavy');
  const freezeHero = wrapper => {
    if (wavyHero) {
      wavyHero.classList.add('stop-animate');
      if (window.innerWidth > 1024) {
        wrapper.style.minHeight = `${wavyHero.offsetHeight + 20}px`;
      }
    }
  };
  const resumeHero = wrapper => {
    if (wavyHero) {
      wavyHero.classList.remove('stop-animate');
      if (window.innerWidth > 1024) {
        wrapper.style.minHeight = `0px`;
      }
    }
  };
  let clickMode = false;
  let focusMode = false;
  menuItems.forEach(menuItem => {
    if (![...menuSubItems].includes(menuItem)) {
      topMenuItems.push(menuItem);

      // Add label to sub-menu
      let wrapper = menuItem.querySelector('.sub-menu-wrap');
      let newLabel = '<div class="sub-menu-label"><span class="h2--xl">' + menuItem.querySelector('a').innerText + '</span></div>';
      wrapper.insertAdjacentHTML('afterbegin', newLabel);
      menuItem.addEventListener('mouseover', () => {
        if (!clickMode && !focusMode) {
          freezeHero(wrapper);
        }
      });
      menuItem.addEventListener('mouseout', () => {
        if (!clickMode && !focusMode) {
          resumeHero(wrapper);
        }
      });
    }
  });
  Array.prototype.forEach.call(topMenuItems, function (el, i) {
    var activatingA = el.querySelector('a');
    var btn = '<button><span><span class="sr-only">Show submenu for “' + activatingA.text + '”</span></span></button>';
    activatingA.insertAdjacentHTML('afterend', btn);
    el.querySelector('button').addEventListener("click", function (event) {
      focusMode = true;
      clickMode = false;
      el.parentNode.classList.add('focus-mode');
      el.parentNode.classList.remove('click-mode');
      const hide = () => {
        this.parentNode.classList.remove("open");
        this.parentNode.querySelector('a').setAttribute('aria-expanded', "false");
        this.parentNode.querySelector('button').setAttribute('aria-expanded', "false");
      };
      if (!this.parentNode.classList.contains("open")) {
        this.parentNode.classList.add("open");
        this.parentNode.querySelector('a').setAttribute('aria-expanded', "true");
        this.parentNode.querySelector('button').setAttribute('aria-expanded', "true");
        let wrapper = this.parentNode.querySelector('.sub-menu-wrap');
        freezeHero(wrapper);
        document.addEventListener('click', e => {
          if (!this.parentElement.contains(e.target)) {
            hide();
            resumeHero(wrapper);
            if (!this.parentElement.parentElement.contains(e.target)) {
              el.parentNode.classList.remove('focus-mode');
              focusMode = false;
            }
          }
        });
      } else {
        hide();
        focusMode = false;
        el.parentNode.classList.remove('focus-mode');
      }
      event.preventDefault();
    });
    activatingA.addEventListener("click", function (event) {
      clickMode = true;
      focusMode = false;
      this.parentNode.parentNode.classList.add('click-mode');
      this.parentNode.parentNode.classList.remove('focus-mode');
      const hide = () => {
        this.parentNode.classList.remove("open");
        this.parentNode.querySelector('a').setAttribute('aria-expanded', "false");
        this.parentNode.querySelector('button').setAttribute('aria-expanded', "false");
      };
      if (!this.parentNode.classList.contains("open")) {
        this.parentNode.classList.add("open");
        this.parentNode.querySelector('a').setAttribute('aria-expanded', "true");
        this.parentNode.querySelector('button').setAttribute('aria-expanded', "true");
        let wrapper = this.parentNode.querySelector('.sub-menu-wrap');
        freezeHero(wrapper);
        document.addEventListener('click', e => {
          if (!this.parentElement.contains(e.target)) {
            hide();
            resumeHero(wrapper);
            if (!this.parentElement.parentElement.contains(e.target)) {
              this.parentNode.parentNode.classList.remove('click-mode');
              clickMode = false;
            }
          }
        });
      } else {
        hide();
        clickMode = false;
        this.parentNode.parentNode.classList.remove('click-mode');
      }
      event.preventDefault();
    });
  });
});