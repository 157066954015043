import { onReady } from './utils';
onReady(() => {
  if (document.querySelectorAll('.encode64').length > 0) {
    let contactLinks = document.querySelectorAll('.encode64');
    contactLinks.forEach(encoded => {
      let encodedString = encoded.href.split(':');
      encodedString[1] = window.atob(encodedString[1]);
      encoded.href = encodedString.join(':');
    });
  }
});