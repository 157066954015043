import { onReady } from './utils';
onReady(() => {
  /**
   * Using partial code from - https://jhartshorne.co.uk/posts/back-to-top/
   * and ideas from https://ashleemboyer.com/blog/accessible-smooth-scroll-to-top-buttons-with-little-code
   * The link that triggers the
   * back to top behaviour.
   * @type {HTMLElement}
   */
  const backToTopButton = document.querySelector('[data-js-hook="back-to-top-button"]');
  const skipLink = document.querySelector('.skip-link');
  window.onscroll = function () {
    scrollFunction();
  };
  function scrollFunction() {
    if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
      backToTopButton.style.opacity = '1';
    } else {
      backToTopButton.style.opacity = '0';
    }
  }

  /**
   * The options used in the native
   * scrollTo method.
   * @type {Object}
   */
  const scrollOptions = {
    top: 0,
    left: 0,
    behavior: 'smooth'
  };

  /**
   * Whether the device supports
   * smooth scroll, or not.
   * @type {Boolean}
   */
  const supportsNativeSmoothScroll = ('scrollBehavior' in document.documentElement.style);

  /**
   * Handles moving the user back to the
   * top of the document.
   * @param event the event object passed in when
   * clicking on the button / link.
   */
  function moveToTop(event) {
    event.preventDefault();

    // Scroll to top.
    supportsNativeSmoothScroll ? window.scrollTo(scrollOptions) : window.scrollTo(scrollOptions.left, scrollOptions.top);

    // Focus the first focusable element.
    skipLink.focus({
      preventScroll: true
    });
  }
  backToTopButton.addEventListener('click', moveToTop);
});