import { onReady } from './utils';
onReady(() => {
  let toggleFn = function () {
    if (document.body.classList.contains('mobile-nav-open')) {
      let oldWidth = document.documentElement.clientWidth;
      document.body.classList.remove('mobile-nav-open');
      let newWidth = document.documentElement.clientWidth;
      let scrollbarWidth = Math.max(0, newWidth - oldWidth);
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      let oldWidth = document.documentElement.clientWidth;
      document.body.classList.add('mobile-nav-open');
      let newWidth = document.documentElement.clientWidth;
      let scrollbarWidth = Math.max(0, newWidth - oldWidth);
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    }
  };
  let openBtn = document.querySelector('.mobile-open');
  let closeBtn = document.querySelector('.mobile-close');
  [openBtn, closeBtn].forEach(btn => {
    btn.addEventListener('click', () => {
      toggleFn();
      if (document.body.classList.contains('mobile-nav-open')) {
        document.querySelector('.navigation-mobile .custom-logo-link').focus();
      } else {
        document.querySelector('.mobile-open').focus();
      }
    });
  });
});