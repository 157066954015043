import _defineProperty from "@babel/runtime/helpers/defineProperty";
export function checkSelector(el, desc) {
  if (!el) throw new Error(`Could not locate ${desc} element!`);
}
;
export const sleep = ms => new Promise(r => setTimeout(r, ms));
export function onReady(fn) {
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

/**
 * Edge cases were found by using the following regex to search lib.dom.d.ts: addEventListener<K extends keyof [\S]*(?<!HTML|SVG)ElementEventMap
 */

export class DisposalSet {
  constructor() {
    _defineProperty(this, "registeredDisposables", void 0);
    _defineProperty(this, "registeredElements", void 0);
    this.registeredDisposables = new Set();
    this.registeredElements = new Map();
  }
  register(target) {
    if (target instanceof Element || target instanceof Document || target instanceof Window) {
      const registrar = {
        addListener: (event, handler, options) => {
          this.registeredElements.set(target, [...(this.registeredElements.get(target) ?? []), [event, handler]]);
          target.addEventListener(event, handler, options);
          return registrar;
        }
      };
      return registrar;
    } else {
      this.registeredDisposables.add(target);
    }
  }
  disposeAll() {
    for (const [element, listeners] of this.registeredElements.entries()) {
      for (const [eventName, listener] of listeners) {
        element.removeEventListener(eventName, listener);
      }
    }
    for (const disposable of this.registeredDisposables.values()) {
      disposable();
    }
    this.registeredDisposables.clear();
    this.registeredElements.clear();
  }
}
;
if (import.meta.webpackHot) {
  import.meta.webpackHot.accept();
}