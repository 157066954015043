import { DisposalSet, checkSelector, onReady } from './utils';
const registry = new DisposalSet();
onReady(() => {
  const form = document.querySelector('.afc-header__right .searchform');
  checkSelector(form, 'search form');
  const mobileForm = document.querySelector('.navigation-mobile .searchform');
  checkSelector(mobileForm, 'mobile search form');
  const container = document.querySelector('.search-form-holder--inner');
  checkSelector(container, 'search form container');
  const mobileContainer = document.querySelector('.navigation-mobile .search-form-holder--inner');
  checkSelector(mobileContainer, 'mobile search form container');
  const searchInput = form.querySelector('input[type="text"]');
  checkSelector(searchInput, 'search input');
  const mobileSearchInput = mobileForm.querySelector('input[type="text"]');
  checkSelector(mobileSearchInput, 'mobile search input');
  const searchSubmit = form.querySelector('input[type="submit"]');
  checkSelector(searchSubmit, 'search submit');
  const mobileSearchSubmit = mobileForm.querySelector('input[type="submit"]');
  checkSelector(mobileSearchSubmit, 'mobile search submit');
  registry.register(searchSubmit).addListener('focus', e => {
    container.classList.add('open');
  }).addListener('click', e => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });
    let queryValue = params.s;
    if (!searchInput.value || searchInput.value === queryValue) {
      e.preventDefault();
      e.stopPropagation();
      container.classList.add('open');
      searchInput.focus();
    }
  });
  registry.register(searchInput).addListener('focusin', e => {
    container.classList.add('open');
  }).addListener('focusout', e => {
    container.classList.remove('open');
  });
  registry.register(searchSubmit).addListener('focusout', e => {
    container.classList.remove('open');
  });
  // Mobile nav version
  registry.register(mobileSearchSubmit).addListener('click', e => {
    console.log('initial click');
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });
    let queryValue = params.s;
    if (!mobileSearchInput.value || mobileSearchInput.value === queryValue) {
      e.preventDefault();
      e.stopPropagation();
      mobileContainer.classList.add('open');
      mobileSearchInput.focus();
    }
  });
});
if (import.meta.webpackHot) {
  import.meta.webpackHot.accept();
  import.meta.webpackHot.dispose(() => {
    registry.disposeAll();
  });
}